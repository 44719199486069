import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Dropdown, } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";

function Header(props) {
    const { address, isConnected, connect, disconnect } = props;
    const location = useLocation();

    const items = [
        {
            key: '1',
            label: (
                <a onClick={disconnect}>
                    <LogoutOutlined />
                    Disconnect
                </a>
            ),
        }
    ];

    return (
        <header>
            <div className="leftH">
                <img src={'/logo.svg'} alt="logo" className="logo" />
                <NavLink to="/" className={location.pathname === "/" ? "active-link" : "link"}>
                    <div className="headerItem">Swap</div>
                </NavLink>
                <NavLink to="/buy-crypto" className={location.pathname === "/buy-crypto" ? "active-link" : "link"}>
                    <div className="headerItem">Buy</div>
                </NavLink>
            </div>
            <div className="rightH">
                {isConnected ? (
                    <Dropdown menu={{items}}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            {address?.slice(0, 4) + "..." + address?.slice(38)} <DownOutlined />
                        </a>
                    </Dropdown>
                ) : (
                    <div className="connectButton" onClick={connect}>
                        Connect Wallet
                    </div>
                )}
            </div>
        </header>
    );
}

export default Header;
