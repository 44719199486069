import React, { useState, useEffect } from "react";
import { Input, Popover, Radio, Modal, message, Button } from "antd";
import {
  ArrowDownOutlined,
  DownOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {tokenListBuy} from "../config";
import { postData } from "../services/api";
import {
  balanceOfBoom,
  balanceOfUsdt,
  callBuyToken,
  checkAllowanceUSDT,
  approveUSDT,
  checkTransactionConfirmation,
  changeNetworkInMetamask,
} from "../blockchain/contract";
import { useSDK } from "@metamask/sdk-react";
import { hexToNumberString } from "../blockchain/provider";
import {getBalanceByAddressContractAddress} from "../helpers";

function BuyToken(props) {
  const { sdk } = useSDK();
  const { address, isConnected } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [slippage, setSlippage] = useState(2.5);
  const [tokenOneAmount, setTokenOneAmount] = useState(null);
  const [tokenTwoAmount, setTokenTwoAmount] = useState(null);
  const [tokenOne, setTokenOne] = useState(tokenListBuy[0]);
  const [tokenTwo, setTokenTwo] = useState(tokenListBuy[1]);
  const [isOpen, setIsOpen] = useState(false);
  const [changeToken, setChangeToken] = useState(1);
  const [prices, setPrices] = useState(null);
  const [txDetails, setTxDetails] = useState({
    to: null,
    data: null,
    value: null,
  });
  const [tokenOneBalance, setTokenOneBalance] = useState(0);
  const [tokenTwoBalance, setTokenTwoBalance] = useState(0);
  const [isLoadingBuy, setIsLoadingBuy] = useState(false);
  const [txidBuy, setTxidBuy] = useState(null);
  const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID)

  function handleSlippageChange(e) {
    setSlippage(e.target.value);
  }


  function changeAmount(e) {
    setTokenOneAmount(e.target.value);
    if (e.target.value && prices) {
      setTokenTwoAmount(Number((e.target.value * prices.ratio).toFixed(2)));
    } else {
      setTokenTwoAmount(null);
    }
  }

  function switchTokens() {
    // setPrices(null);
    // setTokenOneAmount(null);
    // setTokenTwoAmount(null);
    // const one = tokenOne;
    // const two = tokenTwo;
    // setTokenOne(two);
    // setTokenTwo(one);
    // fetchPrices(two.address, one.address);
  }

  function openModal(asset) {
    setChangeToken(asset);
    setIsOpen(true);
  }

  async function fetchBalanceTokenOne() {
    if (address) {
      const balanceUsdt = await balanceOfUsdt(address);
      setTokenOneBalance(balanceUsdt);
    } else {
      setTokenOneBalance(0);
    }
  }

  async function fetchBalanceTokenTwo() {
    if (address) {
      const balance = await balanceOfBoom(address);
      setTokenTwoBalance(balance);
    } else {
      setTokenTwoBalance(0);
    }
  }

  async function fetchBalanceTokens() {
    console.log('address:: ', address)
    const [balanceTokenOne, balanceTokenTwo] = await Promise.all([
      getBalanceByAddressContractAddress(tokenOne.address, address),
      getBalanceByAddressContractAddress(process.env.REACT_APP_BOOM_ADDRESS, address),
    ]);

    setTokenOneBalance(balanceTokenOne);
    setTokenTwoBalance(balanceTokenTwo);
  }

  function modifyToken(i) {
    setPrices(null);
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    if (changeToken === 1) {
      setTokenOne(tokenListBuy[i]);
      fetchBalanceTokenOne();
    } else {
      setTokenTwo(tokenListBuy[i]);
    }
    setIsOpen(false);
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function fetchDexSwap() {
    if (tokenOneAmount) {
      setIsLoadingBuy(true);
      const res = await postData(`/user/sign-buy-token`, {
        wallet_address: address,
        amount: tokenOneAmount,
      });
      const checkAllowance = await checkAllowanceUSDT(
        address,
        process.env.REACT_APP_DEX_ADDRESS,
        res?.data?.estimatePay
      );
      await sdk.connect()

      await changeNetworkInMetamask(
        sdk.getProvider(),
        CHAIN_ID,
        setIsLoadingBuy,
      )

      await sdk.connect()
      let networkId = await sdk
        .getProvider()
        .request({ method: 'eth_chainId', params: [] })
      networkId = hexToNumberString(networkId)

      if (parseInt(networkId) !== parseInt(CHAIN_ID)) return
      if (checkAllowance) {
        approveUSDT(
          sdk.getProvider(),
          address,
          process.env.REACT_APP_DEX_ADDRESS,
          res?.data?.estimatePay,
          setIsLoadingBuy
        ).then((txHash) => {
          checkTransactionConfirmation(txHash).then((r) => {
            if (r === "confirmed") {
              // eslint-disable-next-line
              console.log("Approve USDT:", r, txHash);
              callBuyToken(
                sdk.getProvider(),
                res?.data.params,
                address,
                setIsLoadingBuy
              ).then((txHashBuy) => {
                checkTransactionConfirmation(txHash).then(async (r) => {
                  if (r === "confirmed") {
                    await sleep(5000)
                    // eslint-disable-next-line
                    console.log("Buy token:", txHashBuy);
                    setTxidBuy(txHash)
                    setIsLoadingBuy(false);
                  }
                });
              });
            }
          });
        });
      } else {
        callBuyToken(
          sdk.getProvider(),
          res?.data.params,
          address,
          setIsLoadingBuy
        ).then((txHashBuy) => {
          checkTransactionConfirmation(txHashBuy).then((r) => {
            if (r === "confirmed") {
              // eslint-disable-next-line
              console.log("Buy token:", txHashBuy);
              setTxidBuy(txHashBuy)
              setIsLoadingBuy(false);
            }
          });
        });
      }
    }
  }

  async function fetchPrices() {
    const res = {
      ratio: 1
    }
    setPrices(res)
  }

  useEffect(() => {
    fetchPrices();
  }, []);

  useEffect(() => {
    fetchBalanceTokens();
  }, [address, isConnected, txidBuy]);

  useEffect(() => {
    messageApi.destroy();
    if (txidBuy) {
      messageApi.open({
        type: "success",
        content: "Transaction Successful",
        duration: 3,
      });
    }
  }, [txidBuy]);

  const settings = (
    <>
      <div>Slippage Tolerance</div>
      <div>
        <Radio.Group value={slippage} onChange={handleSlippageChange}>
          <Radio.Button value={0.5}>0.5%</Radio.Button>
          <Radio.Button value={2.5}>2.5%</Radio.Button>
          <Radio.Button value={5}>5.0%</Radio.Button>
        </Radio.Group>
      </div>
    </>
  );

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        footer={null}
        onCancel={() => setIsOpen(false)}
        title="Select a token"
      >
        <div className="modalContent">
          {tokenListBuy?.map((e, i) => {
            return (
              <div
                className="tokenChoice"
                key={i}
                onClick={() => modifyToken(i)}
              >
                <img src={e.img} alt={e.ticker} className="tokenLogo" />
                <div className="tokenChoiceNames">
                  <div className="tokenName">{e.name}</div>
                  <div className="tokenTicker">{e.ticker}</div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      <div className="tradeBox">
        <div className="tradeBoxHeader">
          <h4>Buy $BOOM</h4>
          <h6>Grab some $BOOM and hop into our game!</h6>
          {/* <Popover
          // content={settings}
          // title="Settings"
          // trigger="click"
          // placement="bottomRight"
          >
            <SettingOutlined className="cog" />
          </Popover> */}
        </div>
        <div className="inputs">
          <Input
            placeholder="0"
            value={tokenOneAmount}
            onChange={changeAmount}
          />
          <Input placeholder="0" value={tokenTwoAmount} disabled={true} />
          <div className="switchButton" onClick={switchTokens}>
            <ArrowDownOutlined className="switchArrow" />
          </div>
          <div className="assetOne" onClick={() => openModal(1)}>
            <img src={tokenOne.img} alt="assetOneLogo" className="assetLogo" />
            {tokenOne.ticker}
            <DownOutlined />
            <div className="balanceTwo">Balance: {tokenOneBalance}</div>
          </div>
          <div className="assetTwo">
            <img
              src="/pointIcon.png"
              alt="assetOneLogo"
              className="assetLogo"
            />
            BOOM
            <DownOutlined />
            <div className="balanceTwo">Balance: {tokenTwoBalance}</div>
          </div>
        </div>
        <Button
          type="primary"
          size="large"
          className="swapButton"
          disabled={
            !tokenOneAmount ||
            !isConnected ||
            Number(tokenOneAmount) > Number(tokenOneBalance)
          }
          onClick={fetchDexSwap}
          loading={isLoadingBuy}
        >
          {!isConnected
            ? "Connect Wallet"
            : Number(tokenOneAmount) <= Number(tokenOneBalance)
              ? "Buy"
              : "Insufficient balance"}
        </Button>
      </div>
    </>
  );
}

export default BuyToken;
