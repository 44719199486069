export const tokenListSwap = [
    {
        "ticker": "BOOM",
        "img": "/boomIcon.png",
        "name": "BOOM",
        "address": process.env.REACT_APP_BOOM_ADDRESS,
        "decimals": 6
    },
    {
        "ticker": "Point",
        "img": "/pointIcon.png",
        "name": "Point",
        "address": "Point",
        "decimals": 6
    }
]

export const tokenListBuy = [
    {
        "ticker": "USDT",
        "img": "https://assets.coingecko.com/coins/images/325/standard/Tether.png",
        "name": "Tether USD",
        "address": process.env.REACT_APP_USDT_ADDRESS,
        "decimals": 6
    }
]