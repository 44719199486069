import {balanceOfBoom, balanceOfUsdt} from "../blockchain/contract";
import {getData} from "../services/api";

export const getBalanceByAddressContractAddress = async (addressContract, walletAddress) => {
    if (!walletAddress)
        return 0;

    switch (addressContract) {
        case process.env.REACT_APP_USDT_ADDRESS:
            return balanceOfUsdt(walletAddress);
            break;
        case process.env.REACT_APP_BOOM_ADDRESS:
            return await balanceOfBoom(walletAddress);
        case 'Point':
            const res = await getData(`/user/balance-point/${walletAddress}`);
            return res.balance;
        default:
            return 0;
    }
}