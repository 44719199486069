import "./App.css";
import Header from "./components/Header";
import Swap from "./components/Swap";
import { Routes, Route } from "react-router-dom";
import BuyToken from "./components/BuyTokens";
import { useSDK } from "@metamask/sdk-react";
import { useEffect, useState } from "react";

function App() {
  const { sdk } = useSDK();

  const [address, setAddress] = useState(localStorage.getItem('boom-address'))
  const [boomConnected, setBoomConnected] = useState(localStorage.getItem('boom-connected') || false)

  useEffect(() => {
    if (boomConnected) {
      sdk
          ?.connect()
          .then(() => {
            sdk?.getProvider().on('accountsChanged', (acc) => {
              if (
                acc[0]?.toLowerCase() !==
                address.toLowerCase()
              ) {
                sdk?.disconnect();
                sdk?.terminate();
                setAddress(null);
                setBoomConnected(false)
                localStorage.removeItem('boom-connected')
                localStorage.removeItem('boom-address')
              }
            })
          })
    
    }
  }, [sdk, boomConnected])

  const disconnectMetamask = async () => {
    try {
      sdk?.disconnect();
      sdk?.terminate();
      setAddress(null);
      setBoomConnected(false)
      localStorage.removeItem('boom-connected')
      localStorage.removeItem('boom-address')
    } catch (error) { }
  };
  const connectMetamask = async () => {
    try {
      sdk?.disconnect();
      sdk?.terminate();
      await sdk?.connect().then(account => {
        setAddress(account[0]);
        setBoomConnected(true)
        localStorage.setItem('boom-connected', true)
        localStorage.setItem('boom-address', account[0])
      })
    } catch (error) { }
  };

  return (
    <div className="App">
      <Header
        connect={connectMetamask}
        isConnected={boomConnected}
        address={address}
        disconnect={disconnectMetamask}
      />
      <div className="mainWindow">
        <Routes>
          <Route
            path="/"
            element={<Swap isConnected={boomConnected} address={address} />}
          />
          <Route
            path="/buy-crypto"
            element={<BuyToken isConnected={boomConnected} address={address} />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
