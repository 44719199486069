import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { MetaMaskProvider } from "@metamask/sdk-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MetaMaskProvider
      debug={false}
      sdkOptions={{
        logging: {
          developerMode: false,
        },
        checkInstallationImmediately: false, // This will automatically connect to MetaMask on page load
        dappMetadata: {
          name: "Boom Arena",
          url: window.location.host,
        },
        i18nOptions: {
          enabled: true,
        },
        i18nInstance: "en",
      }}
    >
        <BrowserRouter>
          <App />
        </BrowserRouter>
    </MetaMaskProvider>
  </React.StrictMode>
);
