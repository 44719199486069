import { ethers, BigNumber } from "ethers";

const rpcUrl = process.env.REACT_APP_RPC || "https://api.sepolia.kroma.network/";
console.log('rpcUrl:: ', rpcUrl)
const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

export async function checkTransactionConfirmation(txhash) {
  let checkTransactionLoop = async () => {
    return provider.getTransactionReceipt(txhash)
      .then((r) => {
        if (r != null) {
          return 'confirmed'
        } else {
          return checkTransactionLoop()
        }
      })
      .catch(() => {
        return checkTransactionLoop()
      })
  }
  return checkTransactionLoop()
}

export function hexToNumberString(hex) {
  return BigNumber.from(hex).toString()
}
export function numberToHex(number) {
  return ethers.utils.hexValue(BigNumber.from(String(number)))
}

export function fromWei(number) {
  return ethers.utils.formatEther(String(number));
}

export async function getBalanceOAS(address) {
  return fromWei(String(await provider.getBalance(address)));
}

export function toWei(number) {
  return ethers.utils.parseEther(String(number));
}

export default provider
