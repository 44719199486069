const API_URL = process.env.REACT_APP_API_URL;
console.log('API_URL: ', API_URL)

const handleResponse = async (response) => {
    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Something went wrong');
    }
    return response.json();
};

export const getData = async (endpoint) => {
    const response = await fetch(`${API_URL}${endpoint}`);
    return handleResponse(response);
};

export const postData = async (endpoint, data) => {
    const response = await fetch(`${API_URL}${endpoint}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return handleResponse(response);
};
