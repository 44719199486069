// import provider from '../provider/index'
import { ethers } from "ethers";
import dexAbi from "../abi/Dex.json";
import tokenAbi from "../abi/Token.json";
import { fromWei, numberToHex } from "../provider";

console.log("tokenAbi:: ", tokenAbi);

const USDT_ADDRESS = process.env.REACT_APP_USDT_ADDRESS;
const BOOM_ADDRESS = process.env.REACT_APP_BOOM_ADDRESS;
const DEX_ADDRESS = process.env.REACT_APP_DEX_ADDRESS;
const rpcUrl = process.env.REACT_APP_RPC || "https://api.sepolia.kroma.network/";
// const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

console.log(
  "USDT_ADDRESS: ",
  USDT_ADDRESS,
  "BOOM_ADDRESS: ",
  BOOM_ADDRESS,
  "DEX_ADDRESS: ",
  DEX_ADDRESS
);

let provider;
// Check if MetaMask is installed and enabled
// if (window.ethereum) {
//     console.log('enable')
//     provider = new ethers.providers.Web3Provider(window.ethereum);
//     window.ethereum.enable(); // Request user permission to connect
// } else {
// Fallback to a JsonRpcProvider if MetaMask is not available
provider = new ethers.providers.JsonRpcProvider(rpcUrl);
// }

// Contract Dex
const contractDex = new ethers.Contract(DEX_ADDRESS, dexAbi, provider);

const contractBoom = new ethers.Contract(BOOM_ADDRESS, tokenAbi, provider);

const contractUsdt = new ethers.Contract(USDT_ADDRESS, tokenAbi, provider);

const checkAllowanceBoom = async (owner, spender, amount) => {
  const allowance = await contractBoom.allowance(owner, spender);
  console.log({ allowance: Number(allowance) });
  return Number(allowance) < Number(amount);
};

const checkAllowanceUSDT = async (owner, spender, amount) => {
  const allowance = await contractUsdt.allowance(owner, spender);
  console.log({ allowance: Number(allowance) });
  return Number(allowance) < Number(amount);
};
const checkTransactionConfirmation = (txhash) => {
  let checkTransactionLoop = async () => {
    return provider
      .getTransactionReceipt(txhash)
      .then((r) => {
        if (r != null) {
          return "confirmed";
        } else {
          return checkTransactionLoop();
        }
      })
      .catch(() => {
        return checkTransactionLoop();
      });
  };
  return checkTransactionLoop();
};

const approveBoom = async (provider, owner, spender, amount, setIsLoading) => {
  const txid = provider
    .request({
      method: "eth_sendTransaction",
      params: [
        {
          from: owner,
          data: contractBoom.interface.encodeFunctionData("approve", [
            spender,
            amount,
          ]),
          to: BOOM_ADDRESS,
        },
      ],
    })
    .then((txHash) => {
      return txHash;
    })
    .catch((error) => {
      setIsLoading(false);
      console.error("Error approve boom:", error);
      throw error;
    });
  return txid;
};

const approveUSDT = async (provider, owner, spender, amount, setIsLoading) => {
  const txid = provider
    .request({
      method: "eth_sendTransaction",
      params: [
        {
          from: owner,
          data: contractUsdt.interface.encodeFunctionData("approve", [
            spender,
            amount,
          ]),
          to: USDT_ADDRESS,
        },
      ],
    })
    .then((txHash) => {
      return txHash;
    })
    .catch((error) => {
      setIsLoading(false);
      console.error("Error approve USDT:", error);
      throw error;
    });
  return txid;
};

const callBuyToken = async (provider, param, address, setIsLoading) => {
  const txid = provider
    .request({
      method: "eth_sendTransaction",
      params: [
        {
          from: address,
          data: contractDex.interface.encodeFunctionData("buyToken", [param]),
          to: DEX_ADDRESS,
        },
      ],
    })
    .then((txHash) => {
      return txHash;
    })
    .catch((error) => {
      setIsLoading(false);
      console.error("Error buy token:", error);
      throw error;
    });
  return txid;
};



const callSwapToken = async (provider, param, address, setIsLoading) => {
  const txid = provider
    .request({
      method: "eth_sendTransaction",
      params: [
        {
          from: address,
          data: contractDex.interface.encodeFunctionData("swapToken", [param]),
          to: DEX_ADDRESS,
        },
      ],
    })
    .then((txHash) => {
      return txHash;
    })
    .catch((error) => {
      setIsLoading(false);
      console.error("Error swap token:", error);
      throw error;
    });
  return txid;
};

//
const allowanceContract = async (
  currentAddress,
  allowanceToAddress,
  allowanceAmount
) => {
  try {
    const currentAllowance = await contractBoom.allowance(
      currentAddress,
      allowanceToAddress
    );
    const currentAllowanceNumber = Number(currentAllowance);
    console.log(currentAllowanceNumber, allowanceAmount);

    if (!currentAllowance || allowanceAmount > currentAllowanceNumber) {
      const ethereumProvider = new ethers.providers.Web3Provider(
        window.ethereum
      );
      const signer = ethereumProvider.getSigner();

      const tx = await contractBoom
        .connect(signer)
        .approve(allowanceToAddress, allowanceAmount);
      await tx.wait();
      console.log("tx:: ", tx);
    }
  } catch (e) {
    console.log("error:", e);
  }
};

async function balanceOfBoom(address) {
  return fromWei(String(await contractBoom.balanceOf(address)));
}

async function balanceOfUsdt(address) {
  return fromWei(String(await contractUsdt.balanceOf(address)));
}

async function changeNetworkInMetamask(
  provider,
  chainId,
  setLoading = null,
) {
  var params
  switch (chainId) {
    case 2358:
      params = [
        {
          chainId: '0x936',
          chainName: 'Kroma Testnet',
          nativeCurrency: {
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://api.sepolia.kroma.network/'],
          blockExplorerUrls: ['https://sepolia.kromascan.com'],
        },
      ]
      break
    case 255:
      params = [
        {
          chainId: '0xFF',
          chainName: 'Kroma Mainnet',
          nativeCurrency: {
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://api.kroma.network/'],
          blockExplorerUrls: ['https://kromascan.com'],
        },
      ]
      break
    default:
      params = [
        {
          chainId: '0x936',
          chainName: 'Kroma Testnet',
          nativeCurrency: {
            symbol: 'ETH',
            decimals: 18,
          },
          rpcUrls: ['https://api.sepolia.kroma.network/'],
          blockExplorerUrls: ['https://sepolia.kromascan.com'],
        },
      ]
      break

      break
  }
  try {
    switch (chainId) {
      case 2358:
        // check if the chain to connect to is installed
        return await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x936' }], // chainId must be in hexadecimal numbers
        })
      case 255:
        // check if the chain to connect to is installed
        return await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0xFF' }], // chainId must be in hexadecimal numbers
        })
      default:
        // check if the chain to connect to is installed
        return await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x936' }], // chainId must be in hexadecimal numbers
        })
    }
  } catch (switchError) {
    if (switchError.code === 4902 || switchError.code === -32603) {
      await provider
        .request({
          method: 'wallet_addEthereumChain',
          params: params,
        })
        .then((res) => {
          return res
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.error(addError)
        })
    } else {
      if (setLoading) {
        setLoading(false)
      }
    }
  }
}


export {
  changeNetworkInMetamask,
  approveBoom,
  contractDex,
  contractBoom,
  allowanceContract,
  callSwapToken,
  balanceOfBoom,
  balanceOfUsdt,
  checkAllowanceBoom,
  checkTransactionConfirmation,
  callBuyToken,
  approveUSDT,
  checkAllowanceUSDT
};
