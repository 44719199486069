import React, { useState, useEffect } from "react";
import { Input, Popover, Radio, Modal, message, Button } from "antd";
import {
  DownOutlined,
  SettingOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { getData, postData } from "../services/api";
import {
  approveBoom,
  checkAllowanceBoom,
  checkTransactionConfirmation,
  callSwapToken,
  changeNetworkInMetamask,
} from "../blockchain/contract/index.js";
import { useSDK } from "@metamask/sdk-react";
import { hexToNumberString } from "../blockchain/provider/index.js";
import {getBalanceByAddressContractAddress} from "../helpers";
import {tokenListSwap} from "../config";

function Swap(props) {
  const dexAddress = process.env.REACT_APP_DEX_ADDRESS;
  const { sdk } = useSDK();
  const { address, isConnected } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [isSwapTokenToPoint, setIsSwapTokenToPoint] = useState(true);
  const [slippage, setSlippage] = useState(2.5);
  const [tokenOneAmount, setTokenOneAmount] = useState(null);
  const [tokenTwoAmount, setTokenTwoAmount] = useState(null);
  const [tokenOne, setTokenOne] = useState(tokenListSwap[0]);
  const [tokenTwo, setTokenTwo] = useState(tokenListSwap[1]);
  const [isOpen, setIsOpen] = useState(false);
  const [changeToken, setChangeToken] = useState(1);
  const [prices, setPrices] = useState(null);
  const [tokenOneBalance, setTokenOneBalance] = useState(0);
  const [tokenTwoBalance, setTokenTwoBalance] = useState(0);
  const [isLoadingSwap, setIsLoadingSwap] = useState(false);
  const [txidSwap, setTxidSwap] = useState(null);
  const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID)

  function handleSlippageChange(e) {
    setSlippage(e.target.value);
  }

  function changeAmount(e) {
    setTokenOneAmount(e.target.value);
    if (e.target.value && prices) {
      setTokenTwoAmount(Number((e.target.value * prices.ratio).toFixed(2)));
    } else {
      setTokenTwoAmount(null);
    }
  }

  function switchTokens() {
    setPrices(null);
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    const one = tokenOne;
    const two = tokenTwo;
    setTokenOne(two);
    setTokenTwo(one);
    setTokenOneBalance(tokenTwoBalance);
    setTokenTwoBalance(tokenOneBalance);
    setIsLoadingSwap(false);
    setIsSwapTokenToPoint(!isSwapTokenToPoint);
  }

  function openModal(asset) {
    setChangeToken(asset);
    setIsOpen(true);
  }

  function modifyToken(i) {
    setPrices(null);
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
    if (changeToken === 1) {
      setTokenOne(tokenListSwap[i]);
      fetchPrices(tokenListSwap[i].address, tokenTwo.address);
    } else {
      setTokenTwo(tokenListSwap[i]);
      fetchPrices(tokenOne.address, tokenListSwap[i].address);
    }
    setIsOpen(false);
  }

  async function fetchPrices(one, two) {
    const res = {
      ratio: 1
    }
    setPrices(res)
  }

  async function fetchBalanceTokens() {
    if (address) {
      const balanceOne = await getBalanceByAddressContractAddress(tokenOne?.address, address);
      const balanceTwo =  await getBalanceByAddressContractAddress(tokenTwo?.address, address);
      setTokenOneBalance(balanceOne || 0);
      setTokenTwoBalance(balanceTwo || 0);
    } else {
      setTokenOneBalance(0);
      setTokenTwoBalance(0);
    }
  }

  async function fetchDexSwap() {
    try {
      setIsLoadingSwap(true);
      const typeSwap = isSwapTokenToPoint
        ? "SWAP_TOKEN_TO_POINT"
        : "SWAP_POINT_TO_TOKEN";
      console.log("tokenOneAmount:: ", tokenOneAmount);
      const dataSign = await postData(`/user/sign-swap-token`, {
        wallet_address: address,
        type_swap: typeSwap,
        amount: tokenOneAmount,
      });
      const checkAllowance = await checkAllowanceBoom(
        address,
        dexAddress,
        dataSign?.data?.estimatePay
      );
      await sdk.connect()

      await changeNetworkInMetamask(
        sdk.getProvider(),
        CHAIN_ID,
        setIsLoadingSwap,
      )
      await sdk.connect()
      let networkId = await sdk
        .getProvider()
        .request({ method: 'eth_chainId', params: [] })
      networkId = hexToNumberString(networkId)

      if (parseInt(networkId) !== parseInt(CHAIN_ID)) return
      if (checkAllowance && typeSwap == 'SWAP_TOKEN_TO_POINT') {
        approveBoom(
          sdk.getProvider(),
          address,
          dexAddress,
          dataSign?.data?.estimatePay,
          setIsLoadingSwap
        ).then((txHash) => {
          checkTransactionConfirmation(txHash).then((r) => {
            if (r === "confirmed") {
              // eslint-disable-next-line
              console.log("Approve Boom:", r, txHash);
              callSwapToken(
                sdk.getProvider(),
                dataSign?.data.params,
                address,
                setIsLoadingSwap
              ).then(async (txHashSwap) => {
                console.log("Swap token:", txHashSwap);
                let checkStatus = setInterval(async () => {
                  const resCheckStatus = await getData(
                    `/swap/check-swap/${dataSign?.data.params["_sig"]}`
                  );
                  if (resCheckStatus.status) {
                    console.log("Swap token:", txHashSwap);
                    clearInterval(checkStatus);
                    setTxidSwap(txHashSwap)
                    setIsLoadingSwap(false);
                  }
                }, 1000);
              });
            }
          });
        });
      } else {
        callSwapToken(
          sdk.getProvider(),
          dataSign?.data.params,
          address,
          setIsLoadingSwap
        ).then((txHashSwap) => {
          let checkStatus = setInterval(async () => {
            const resCheckStatus = await getData(
              `/swap/check-swap/${dataSign?.data.params["_sig"]}`
            );
            if (resCheckStatus.status) {
              console.log("Swap token:", txHashSwap);
              clearInterval(checkStatus);
              setTxidSwap(txHashSwap)
              setIsLoadingSwap(false);
            }
          }, 1000);
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoadingSwap(false);
    }
  }

  useEffect(() => {
    fetchPrices(tokenListSwap[0].address, tokenListSwap[1].address);
  }, [tokenOne, tokenTwo]);


  useEffect(() => {
    fetchBalanceTokens();
    messageApi.destroy();
    if (txidSwap) {
      messageApi.open({
        type: "success",
        content: "Transaction Successful",
        duration: 3,
      });
    }
    setTokenOneAmount(null);
    setTokenTwoAmount(null);
  }, [txidSwap, address]);

  const settings = (
    <>
      <div>Slippage Tolerance</div>
      <div>
        <Radio.Group value={slippage} onChange={handleSlippageChange}>
          <Radio.Button value={0.5}>0.5%</Radio.Button>
          <Radio.Button value={2.5}>2.5%</Radio.Button>
          <Radio.Button value={5}>5.0%</Radio.Button>
        </Radio.Group>
      </div>
    </>
  );
  console.log({ tokenOne });
  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        footer={null}
        onCancel={() => setIsOpen(false)}
        title="Select a token"
      >
        <div className="modalContent">
          {tokenListSwap?.map((e, i) => {
            return (
              <div
                className="tokenChoice"
                key={i}
                onClick={() => modifyToken(i)}
              >
                <img src={e.img} alt={e.ticker} className="tokenLogo" />
                <div className="tokenChoiceNames">
                  <div className="tokenName">{e.name}</div>
                  <div className="tokenTicker">{e.ticker}</div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      <div className="tradeBox">
        <div className="tradeBoxHeader">
          <h4>Swap</h4>
          <h6>Switch between BoomPoint and $Boom in a snap!</h6>
          {/* <Popover
            content={settings}
            title="Settings"
            trigger="click"
            placement="bottomRight"
          >
            <SettingOutlined className="cog" />
          </Popover> */}
        </div>
        <div className="inputs">
          <Input
            placeholder="0"
            value={tokenOneAmount}
            onChange={changeAmount}
          />
          <Input placeholder="0" value={tokenTwoAmount} disabled={true} />
          <div className="switchButton" onClick={switchTokens}>
            <SwapOutlined className="switchArrow" style={{ fontSize: '18px', transform: 'rotate(90deg)' }}/>
          </div>
          <div
            className="assetOne"
          // onClick={() => openModal(1)}
          >
            <img src={tokenOne.img} alt="assetOneLogo" className="assetLogo" />
            {tokenOne.ticker}
            <DownOutlined />
          </div>
          <div className="balanceOne">Balance: {tokenOneBalance}</div>
          <div
            className="assetTwo"
          // onClick={() => openModal(2)}
          >
            <img src={tokenTwo.img} alt="assetOneLogo" className="assetLogo" />
            {tokenTwo.ticker}
            <DownOutlined />
            <div className="balanceTwo">Balance: {tokenTwoBalance}</div>
          </div>
        </div>
        <Button
          type="primary"
          size="large"
          className="swapButton"
          disabled={
            !tokenOneAmount ||
            !isConnected ||
            Number(tokenOneAmount) > Number(tokenOneBalance)
          }
          onClick={fetchDexSwap}
          loading={isLoadingSwap}
        >
          {!isConnected
            ? "Connect Wallet"
            : Number(tokenOneAmount) <= Number(tokenOneBalance)
              ? "Swap"
              : "Insufficient balance"}
        </Button>
      </div>
    </>
  );
}

export default Swap;
